<template>
    <div class="search-label">
        <div class="float-left">
            <span>共 <span class="color-orange">{{count}}</span> 个职位</span>
        </div>
        <div class="float-center">
            <div class="select-tag-list">
                <div class="select-tag"  v-for="(item,tag) in orderLabelShowList" :key="tag" v-show="orderLabelShowList.length > 0">
                    <el-tooltip placement="top-start" effect="light" popper-class="tooltip-label">
                        <div slot="content">{{labelList[item] | labelsTip}}</div>
                        <div class="text-ellipsis">
                            <span>{{ searchParamTitle[item] ? `${searchParamTitle[item]}：` : '' }} </span>
                            <span class="content" v-for="(param, index) in labelList[item]" :key="index">
                                {{ param.text }}
                                <span v-if="labelList[item].length > 0 && index !== (labelList[item].length - 1)"> , </span>
                            </span>
                        </div>
                    </el-tooltip>
                    <span class="btn-close" @click="handleCleanLabel(item,)"></span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "searchLabel",
        componentName: "searchLabel",
        data (){
            return {
                searchParamTitle: {
                    bubble: '气泡筛选',
                    titles: '职位名称',
                    customerNames: '客户公司',
                    status: '职位状态',
                    commissionType: '佣金情况',
                    tags: '标签',

                    customerName: '客户公司',
                    approvedStatus: '审批状态',
                }
            }
        },
        props: {
            orderLabelShowList: {
                default: [],
                type: Array,
            },
            count: {
                default: 0,
                type: Number,
            },
            labelList: {
                bubble: [],
                titles: [],
                customerNames: [],
                status: [],
                commissionType: [],
                tags: []
            }
        },
        filters :{
            labelsTip(labels){
                let tip =[];
                labels.forEach(item => {
                    tip.push(item.text)
                });
                return tip.join(' , ');
            }
        },
        methods: {
            handleCleanLabel (label) {
                this.$emit('handleCleanLabel', label);
            }
        },
    }
</script>

<style lang="scss" scoped>
    .color-orange{
        color: $orange;
    }
    .search-label{
        margin: 0 20px;
        line-height: 44px;
        .float-left{
            float: left;
        }
    }
    .select-tag-list {
        display: block;
        padding: 10px 0 5px 10px;
        overflow: hidden;
        .select-tag {
            display: inline-block;
            position: relative;
            float: left;
            margin-right: 10px;
            margin-bottom: 5px;
            padding: 0 28px 0 8px;
            max-width: 486px;
            height: 24px;
            line-height: 24px;
            color: #999;
            background: #F8F8F8;
            border: 1px solid #ddd;
            border-radius: 4px;
            &:last-child {
                margin-right: 0;
            }
            .btn-close {
                float: right;
                position: absolute;
                right: 10px;
                top: 0;
                &:after {
                    display: inline-block;
                    content: '+';
                    float: right;
                    width: 14px;
                    height: 24px;
                    transform: rotate(-45deg);
                    font-size: 20px;

                    text-align: center;
                    line-height: 22px;
                    cursor: pointer;
                }
                &:hover {
                    color: $primary;
                }
            }
        }
    }
</style>

<style lang="scss">
    .el-tooltip__popper.tooltip-label.is-light{
        max-width: 480px;
    }
</style>
